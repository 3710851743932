export const endpoint = '/assets/components/site/connector.php'
export const splitter = ','

export function encodeParams(params) {
  const values = {}
  const keys = Object.keys(params)
  for (const key of keys) {
    let value = params[key]
    if (typeof value === 'object') {
      value = value.map((i) => encodeURIComponent(i)).join(splitter)
    }
    if (value.length) {
      values[key] = value.replace(/&/g, '%26')
    }
  }

  return decodeURIComponent(new URLSearchParams(values).toString())
}

export function decodeParams(string) {
  const params = {}
  const parts = string.replace(/\+/g, '%20').split('&')

  for (const part of parts) {
    const [key, value] = part.split('=')
    if (key !== 'page' && value) {
      params[key] = value.includes(splitter)
        ? value.split(splitter).map((i) => decodeURIComponent(i))
        : decodeURIComponent(value)
    }
  }

  return params
}