import {encodeParams, decodeParams, endpoint} from "./utils.js";
import {config as filtersConfig} from './filters.js'

const initialData = {
  products: document.getElementById('dupli-colorguide-products')?.innerHTML,
  pagination: document.getElementById('dupli-colorguide-pagination')?.innerHTML,
  // total: document.getElementById('dupli-filters-total')?.textContent
}

export const elem = 'dupli-colorguide'

export const config = {
  ...filtersConfig,
  filters: {
    color_code: '',
    color_desc: '',
    color_brand: '',
    year: '',
  },
  init() {
    this.products = initialData.products
    this.pagination = initialData.pagination
    this.total = initialData.total

    const query = document.location.search
    if (query.length > 1) {
      const filters = decodeParams(document.location.search.slice(1))
      const keys = Object.keys(filters)
      for (const key of keys) {
        this.filters[key] = typeof this.filters[key] === 'object' && typeof filters[key] === 'string'
          ? [filters[key]]
          : filters[key]
      }
    }

    this.numbers = window.__Filters || {}
  },
  async onSubmit(page = undefined) {
    const params = {
      action: 'api/web/dupli-colorguide'
    }
    if (page && page > 1) {
      params.page = page
    }
    try {
      this.loading = true
      let encoded = encodeParams(this.filters)
      if (params.page) {
        encoded += (encoded.length ? '&' : '') + 'page=' + params.page
      }
      window.history.pushState(encoded, '', document.location.pathname + (encoded ? ('?' + encoded) : ''))

      const response = await fetch(endpoint + '?' + new URLSearchParams(params).toString(), {
        method: 'POST',
        body: JSON.stringify({filters: this.filters, res: document.body.dataset.res}),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
      if (response.ok) {
        const data = await response.json()
        this.total = data.total
        this.products = data.products
        this.pagination = data.pagination
        this.numbers = data.filters
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  },
  get textFiltersActive() {
    return this.filters.color_code.length > 0 || this.filters.color_desc.length > 0
  },
  get filtersActive() {
    return Object.values(this.filters).join('').length > 0
  },
}