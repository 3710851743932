import {createApp} from 'petite-vue'

import {config as filtersConfig, elem as filtersElem} from "./dupli/filters.js"
import {config as guideConfig, elem as guideElem} from "./dupli/colorguide.js"
import {config as primerConfig, elem as primerElem} from "./dupli/recommended-primer.js"
import {config as itemsConfig, elem as itemsElem} from "./dupli/product-items.js"

if (document.getElementById(filtersElem)) {
  createApp(filtersConfig).mount('#' + filtersElem)
}

if (document.getElementById(guideElem)) {
  createApp(guideConfig).mount('#' + guideElem)
}

if (document.getElementById(primerElem)) {
  createApp(primerConfig).mount('#' + primerElem)
}

if (document.getElementById(itemsElem)) {
  createApp(itemsConfig).mount('#' + itemsElem)
}

const link = document.getElementById('link-360')
if (link) {
  link.addEventListener('click', ({currentTarget}) => {
    if (!currentTarget.dataset.url) {
      return
    }

    const outer = document.createElement('iframe')
    outer.src = currentTarget.dataset.url
    outer.style.width = '400px'
    outer.style.height = '700px'

    window.lightcase.start({
      href: '#image-360',
      forceWidth: true,
      forceHeight: true,
      width: 700,
      height: 400,
      onBeforeShow: {
        quux() {
          const container = document.getElementById('lightcase-content')
          const wrapper = container.firstChild.firstChild
          wrapper.replaceChild(outer, wrapper.firstChild)
        }
      }
    })
  })
}