export const elem = 'product-items'

export const config = {
  $delimiters: ['<<', '>>'],

  init() {
    //
  },
  onChange(event) {
    console.log('Fetching product items...');
    event.target.form.classList.add('loading');
    event.target.form.submit();
  }
}