import {decodeParams} from './utils.js'

export const elem = 'recommended-primer'
export const config = {
  $delimiters: ['<<', '>>'],
  selected: '',
  init(id) {
    const search = document.location.search.replace(/^\?/, '')
    const params = decodeParams(search)
    if (params.surfaces) {
      this.selected = params.surfaces
      this.default = params.surfaces
    } else if (id) {
      this.selected = id
    }
  },
  select(type) {
    this.selected = type
  },
  reset() {
    this.selected = ''
  }
}