import {encodeParams, decodeParams, endpoint} from "./utils.js";

const initialData = {
  products: document.getElementById('dupli-filters-products')?.innerHTML,
  pagination: document.getElementById('dupli-filters-pagination')?.innerHTML,
  total: document.getElementById('dupli-filters-total')?.textContent
}

export const elem = 'dupli-filters'
export const config = {
  $delimiters: ['<<', '>>'],
  loading: false,
  filters: {
    // segment: '',
    indoor_outdoor: '',
    surfaces: '',
    specials: [],
    color_family: '',
    group: [],
    family: [],
    gloss_type: [],
    volume: [],
  },
  numbers: {},
  total: 0,
  products: '',
  pagination: '',
  init() {
    this.products = initialData.products
    this.pagination = initialData.pagination
    this.total = initialData.total

    const query = document.location.search
    if (query.length > 1) {
      const filters = decodeParams(document.location.search.slice(1))
      const keys = Object.keys(filters)
      for (const key of keys) {
        this.filters[key] = typeof this.filters[key] === 'object' && typeof filters[key] === 'string'
          ? [filters[key]]
          : filters[key]
      }
    }

    this.numbers = window.__Filters || {}
  },
  async onSubmit(page = undefined) {
    const params = {
      action: 'api/web/dupli-filters',
    }
    if (page && page > 1) {
      params.page = page
    }
    try {
      this.loading = true
      let encoded = encodeParams(this.filters)
      if (params.page) {
        encoded += (encoded.length ? '&' : '') + 'page=' + params.page
      }
      window.history.pushState(encoded, '', document.location.pathname + (encoded ? ('?' + encoded) : ''))

      const response = await fetch(endpoint + '?' + new URLSearchParams(params).toString(), {
        method: 'POST',
        body: JSON.stringify({filters: this.filters, res: document.body.dataset.res}),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
      if (response.ok) {
        const data = await response.json()
        this.total = data.total
        this.products = data.products
        this.pagination = data.pagination
        this.numbers = data.filters
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  },
  onChange({target}) {
    if (target.tagName === 'SELECT' && target.name === 'segment') {
      document.location = target.value
    } else {
      this.onSubmit()
    }
  },
  onReset() {
    const keys = Object.keys(this.filters)
    for (const key of keys) {
      this.filters[key] = typeof this.filters[key] === 'object' ? [] : ''
    }
    this.onSubmit()
  },
  getNumber(key, value = undefined) {
    if (!this.numbers[key]) {
      return 0
    }

    if (value) {
      return this.numbers[key][value] ? this.numbers[key][value] : 0
    }

    let total = 0

    Object.keys(this.numbers[key]).forEach((value) => {
      total += this.numbers[key][value]
    })

    return total
  },
  toggleGroup({target}) {
    let checkboxes = target.nextElementSibling.querySelectorAll('[type="checkbox"]');

    if (checkboxes.length > 0) {
      let newState = false;

      for (let i = 0; i < checkboxes.length; i++) {
        if (!checkboxes[i].checked) {
          newState = true;
          break;
        }
      }

      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = newState;

        /* Fill filters values */
        let name  = checkboxes[i].getAttribute('name');
        let value = checkboxes[i].value;

        if (newState) {
          if (this.filters[name].indexOf(value) === -1) {
            this.filters[name].push(value);
          }
        } else {
          this.filters[name] = [];
        }
      }

      this.onSubmit();
    }
  },
  async onPage(e) {
    e.preventDefault()
    if (e.target.tagName === 'A') {
      const page = e.target.dataset.page
      if (page !== undefined) {
        await this.onSubmit(page)
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  },
  get filtersActive() {
    let filters = 0
    const keys = Object.keys(this.filters)
    for (const key of keys) {
      if (typeof this.filters[key] === 'object') {
        filters += this.filters[key].length
      } else if (this.filters[key].length) {
        filters++
      }
    }
    return filters > 0
  },
}